import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

window.addEventListener('beforeinstallprompt', (event) => {
  window.deferredPrompt = event;
  event.preventDefault();
  const divInstall = document.getElementById('installContainer');
  console.log('👍', 'beforeinstallprompt', event);
  // Stash the event so it can be triggered later.
  
  
  // Remove the 'hidden' class from the install button container
  divInstall.classList.toggle('hidden', false);
});


if ('serviceWorker' in navigator) {
  console.log("serviceWorker in navigator")
  window.addEventListener('load', function() {
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
