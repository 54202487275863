import React, { Component } from 'react';
import './App.css';
import axios from 'axios'
import Loader from './Radio.svg';

const getCountDownTime = () => {
  let counter = 5;
  if(window.location.host.includes('heirbom')) counter = 8;
  if(window.location.host.includes('localhost')) counter = 3;

  return counter;
}

const installApp = async () => {
  const action = window.deferredPrompt
  action.prompt();
  // Wait for the user to respond to the prompt
  const { outcome } = await action.userChoice;
  // Optionally, send analytics event with outcome of user choice
  console.log(`User response to the install prompt: ${outcome}`);
  if(outcome === 'accepted') {
    const divInstall = document.getElementById('installContainer');
    divInstall.classList.toggle('hidden', true);
  }
  // We've used the prompt, and can't use it again, throw it away
  window.deferredPrompt = null;
}
class App extends Component {

  constructor(props) {
    super(props);
    this.state =  {
        text:"Åpne bom"
    }
  }

  countDownInit() {
    const counter = getCountDownTime();
    this.setState({loading:false, counter})
    this.nIntervId = setInterval(this.countDown.bind(this), 1000);
  }

  countDown() {
    console.log("counting down", this.state.counter)
    if(this.state.counter===1){
      clearInterval(this.nIntervId);
      this.setState({text:"Kjør!"})
    }
    this.setState({counter:this.state.counter-1})
  }

  async startOpen() {
    this.setState({loading:true, failed:false})
    try {
      let res = await axios.get('https://europe-west1-open-bom.cloudfunctions.net/open');
      // let res = await axios.get('http://localhost:5000/open-bom/europe-west1/open');
      if(res.status === 200) {
        const countDownFunc = this.countDownInit.bind(this)
        countDownFunc()
      }
    } catch (error) {
      console.log("errorr")
      this.setState({failed:true, loading: false})
      console.log(error)
    }
  }

  logicView() {
    if(this.state && this.state.loading) {
      return (<div id="button"><img src={Loader} className="App-logo" alt="logo" /></div>)
    }
    if(this.state && this.state.counter) {
      return (<div id="button">{this.state.counter}</div>)
    }
    if(this.state && this.state.failed) {
      return (<div id="button" onClick={this.startOpen.bind(this)}>Prøv på nytt!</div>)
    }
    return (
      <div className="App">
        <div id="button" onClick={this.startOpen.bind(this)}>{this.state.text}</div>
      </div>
    );
  }

  render() {


    return (
      <>
        {this.logicView()}
        <button onClick={installApp} className={'hidden'} id={'installContainer'} style={{width: '180px', marginTop: '30px', padding: '20px'}}>Installer på telefonen</button>
      </>
    )

  }
}

export default App;
